<script>
import statsV3 from '@/mixins/statsV3'
import displayNote from '@/mixins/displayNote'
import boutiqueSup from '@/mixins/boutiqueSup'
import { round, uniq, max } from 'lodash-es'
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'ExerciceStatsGraph',
  components: { apexchart: VueApexCharts },
  props: {
    contenus: { type: Array, required: true },
    lectureGroups: { type: Array, required: true },
    start: { type: String, default: null }
  },
  mixins: [statsV3, boutiqueSup, displayNote],
  data () {
    return {
      globalStats: null,
      statsForEveryGroups: [],
      loading: false,
      periode: { value: 'SEMAINE', text: 'Semaine', title: 'semaine' },
      property: { value: 'avg', text: 'Notes moyennes' },
      displayEveryGroups: true,
      graphs: []
    }
  },
  mounted () {
    this.fetchGraphList() // The result of this fetch will trigger updateGraphData
  },
  methods: {
    async updateGraphData () {
      const filtre = {
        graphs: this.graphs,
        usagers: this.users,
        debut: this.start,
        fin: this.$dayjs().format()
      }
      const options = { periode: this.periode.value, includeHisto: true }
      this.loading = true
      this.globalStats = await this.getStats(filtre, options)
      this.globalStats.groupTitre = 'Toutes classes'
      this.statsForEveryGroups = await this.fetchStatsForEveryGroups(filtre, options)
      this.loading = false
    },
    async fetchStatsForEveryGroups (filtre, options) {
      if (this.onlyOneGroup) {
        return []
      }
      const statsEveryGroupsSettled = await Promise.allSettled(
        this.lectureGroups.map(lg => {
          const usagers = lg.usagers.map(u => u?.info?.username)
          filtre.usagers = usagers
          options.extraData = { groupTitre: lg.titre }
          return this.getStats(filtre, options)
        })
      )
      return statsEveryGroupsSettled.map(r => r.value)
    },
    async fetchGraphList () {
      const { data } = await this.$apollo.query({
        query: require('@/graphql/queries/v2/analyse/calendrierCalendrierElementsVariablesContenu.gql'),
        variables: {
          filtre: {
            identifiants: [this.$route.params.id]
          },
          limit: 1
        }
      })
      const graphsList = data.calendrierCalendrierElements.resultat[0].variablesContenu.graphIds

      this.graphs = graphsList
    },
    mapDataByAvg (data) {
      return data.avg === null ? null : round(data.avg, 1)
    },
    mapDataByMin (data) {
      return data.min === null ? null : round(data.min, 1)
    },
    mapDataByMax (data) {
      return data.max === null ? null : round(data.max, 1)
    },
    mapDataByCount (data) {
      return data.count ? Math.round(data.count) : null
    },
    fillGapWithPrecedentValue (array) {
      let lastValue = null
      return array.map(value => {
        lastValue = value === null ? lastValue : value
        return lastValue
      })
    },
    histoToDataSeries (histo) {
      if (this.property?.value === 'min') {
        return this.fillGapWithPrecedentValue(histo.map(this.mapDataByMin))
      }
      if (this.property?.value === 'max') {
        return this.fillGapWithPrecedentValue(histo.map(this.mapDataByMax))
      }
      if (this.property?.value === 'count') {
        return histo.map(this.mapDataByCount)
      }
      // Else: property is Average
      return histo.map(this.mapDataByAvg)
    }
  },
  computed: {
    allStats () {
      const first = this.globalStats
      if (!first) {
        return []
      }
      return this.displayEveryGroups && !this.onlyOneGroup ? [first, ...this.statsForEveryGroups] : [first]
    },
    labels () {
      return this.allStats?.[0]?.historique?.map(h => this.$dayjs(h.debut).locale(this.$i18n.locale).format('DD MMM YY'))
    },
    users () {
      const users = []
      this.lectureGroups.forEach(lg => {
        lg.usagers.forEach(u => {
          if (u?.info?.username) {
            users.push(u.info.username)
          }
        })
      })
      return uniq(users)
    },
    decimals () {
      return this.property?.value === 'count' ? 0 : 1
    },
    maxDataSeries () {
      const p = this.property.value
      if (p === 'avg' || p === 'min' || p === 'max') {
        return 100
      }

      return max(this.series.map(s => max(s.data)))
    },
    chartOptions () {
      return {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          animations: {
            enabled: false
          }
        },
        stroke: {
          width: 5,
          curve: 'smooth'
        },
        labels: this.labels,
        title: {
          text: ''
        },
        xaxis: {},
        yaxis: {
          min: 0,
          max: this.maxDataSeries,
          decimalsInFloat: this.decimals,
          labels: {
            formatter: this.noteFormatter
          }
        },
        markers: {
          size: 5,
          hover: {
            size: 9
          }
        }
      }
    },
    series () {
      return this.allStats.map(s => ({
        name: this.onlyOneGroup ? this.property.text : `${this.property.text} - ${s.groupTitre}`,
        data: this.histoToDataSeries(s.historique)
      }))
    },
    onlyOneGroup () {
      return this.lectureGroups.length <= 1
    },
    periodeOptions () {
      return [
        { value: 'JOUR', text: 'Par jours', title: 'Par jours' },
        { value: 'SEMAINE', text: 'Par semaines', title: 'Par semaines' },
        { value: 'MOIS', text: 'Par mois', title: 'Par mois' },
        { value: 'ANNEE', text: 'Par années', title: 'Par années' }
      ]
    },
    properties () {
      return [
        { value: 'avg', text: 'Notes moyennes' },
        { value: 'min', text: 'Notes minimum' },
        { value: 'max', text: 'Notes maximum' },
        { value: 'count', text: "Nombre d'exercices réalisées" }
      ]
    }
  },
  watch: {
    graphs () {
      this.updateGraphData()
    },
    periode () {
      this.updateGraphData()
    }
  }
}
</script>

<template lang="pug">
.exercice-stats-graph
  h2 {{property.text}} en fonction {{periode.title}}
  //- .apexchart-container
  //-   apexchart(type="line" height="350" :options="chartOptions" :series="series")
  //-   transition(name="fade")
  //-   .loading(v-if="loading")
  //-     v-progress-circular(indeterminate color="secondary")
  //- .stats-customs
  //-   h2 Paramètres
  //-   .stats-customs-row
  //-     v-select(v-model="property" :items="properties" label="Propriété" outlined dense attach return-object)
  //-     v-select(v-model="periode" :items="periodeOptions" label="Période" outlined dense attach return-object)
  //-   .stats-customs-row(v-if="!onlyOneGroup")
  //-     v-checkbox(v-model="displayEveryGroups" label="Afficher le détail des classes" hide-details)

</template>

<style lang="sass" scoped>
.exercice-stats-graph
  padding: 16px 12px
  .stats-customs
    display: flex
    flex-direction: column
    padding: 0 12px
    h2
      margin-bottom: 12px
    h3
      margin-bottom: 8px
    .stats-customs-row
      display: flex
      align-items: center
      gap: 12px
      & > *
        flex-grow: 0
        flex-basis: 50%
  .v-input--selection-controls
    margin-top: 0
    padding-top: 0
  .apexchart-container
    position: relative
    .loading
      position: absolute
      top: 0
      left: 0
      height: 100%
      width: 100%
      display: flex
      align-items: center
      justify-content: center
      background: rgba(0,0,0,0.2)

.fade-enter-active,
.fade-leave-active
  transition: opacity 0.5s ease

.fade-enter-from,
.fade-leave-to
  opacity: 0
</style>
