<script>
import ChatControls from '@/mixins/chatControls.js'
import BoutiqueSup from '@/mixins/boutiqueSup.js'
import ExerciceInformation from '@/components/exercices/exerciceInformations.vue'
import extractFromMeta from '@/mixins/extractFromMeta'
import openLink from '@/mixins/openLink.js'

export default {
  name: 'ExercicePreview',
  components: {
    ExerciceInformation
  },
  mixins: [ChatControls, BoutiqueSup, extractFromMeta, openLink],
  props: {
    hardcodded: { required: false, type: Boolean, default: false },
    data: { required: true, type: Object },
    index: { required: true, type: Number }
  },
  computed: {
    activeContenu () {
      let temp = []
      if (this.data?.meta && this.boutiqueItem?.contenus?.length > 0) {
        const parseData = JSON.parse(this.data.meta)
        if (parseData.data && parseData.data.contenu && parseData.data.contenu.length > 0) {
          temp = this.boutiqueItem.contenus.filter(e => parseData.data.contenu.find(c => c === e.id))
        }
        // const parseData = this.
      }
      return temp
    },
    nombreExercices () {
      let temp = 0
      if (this.supergraph && this.supergraph.supers_noeuds.length > 0) {
        this.supergraph.supers_noeuds.forEach((noeud) => {
          if (noeud.graph && noeud.graph.tag_invisibles.length > 0) {
            let tagRedirect = false
            let tagTargetBlank = false
            noeud.graph.tag_invisibles.forEach((tag) => {
              if (tag.id === '0f9f6c95-06c0-47b3-89bf-11dcc837746f') {
                tagTargetBlank = true
              } else if (tag.id === '38b8bb6b-b987-4a7f-bee1-e93b0a7dd6f3') {
                tagRedirect = true
              }
            })
            if (!tagTargetBlank && !tagRedirect) {
              temp++
            }
          } else {
            temp++
          }
        })
      }
      return temp
    },
    getStatus () {
      let status = {
        color: 'grey',
        title: 'Non-défini'
      }
      if (this.data.statut === 'Actif') {
        status.color = 'success'
        status.title = this.$t('messages.a-faire')
      } else if (this.data.statut === 'Archive') {
        status.color = 'error'
        status.title = this.$t('messages.termine')
      } else if (this.data.statut === 'approuvé' || this.data.statut === 'encours') {
        status.color = 'action'
        status.title = this.$t('messages.en-cours')
      } else {
        status.color = 'error'
        status.title = this.$t('messages.statut-inconnu')
      }
      return status
    },
    type () {
      if (this.data && this.data.contenu && this.data.contenu.__typename === 'BoutiqueItem') {
        return 'boutique'
      } else if (this.data && this.data.meta && this.data.meta.includes('SuperNoeud')) {
        return 'supernoeud'
      } else if (this.data && this.data.meta && this.data.meta.includes('Super/')) {
        return 'graph'
      } else if (this.data && this.data.meta && this.data.meta.includes('boutiqueItem')) {
        return 'boutique'
      } else if (this.data?.contenu?.__typename === 'GraphArbre' && this.data?.contenu?.noeuds?.length) {
        return 'myquestions'
      } else {
        return null
      }
    },
    id () {
      const { id } = this.typeAndIdFromMeta(this.data?.meta)
      return id
    },
    niveauOrdred () {
      const sortDesc = (a, b) => a - b
      return [...this.infoSup?.niveau_ids].sort(sortDesc)
    },
    myquestionsExoList () {
      if (this.type !== 'myquestions') {
        return []
      }

      // Exclude exerciceTheorie from stats
      return this.data.contenu.noeuds.filter((n, index) => {
        if (index === this.data.contenu.noeuds.length - 1 && n.contenu?.__typename === 'ExercicesTheorie') {
          return false
        }
        return true
      })
    }
  },
  apollo: {
    supergraph: {
      query: require('@/graphql/queries/plus/supergraph.gql'),
      client: 'plus',
      variables () {
        return {
          id: this.id
        }
      },
      update (data) {
        return data.super_graph
      },
      skip () {
        return (!this.id || !this.data || this.type !== 'graph')
      },
      loadingKey: 'loading',
      watchLoading (isLoading) {
        this.supergraphLoading = isLoading
      }
    },
    supernoeud: {
      query: require('@/graphql/queries/plus/supernoeud.gql'),
      client: 'plus',
      variables () {
        return {
          id: this.id
        }
      },
      update (data) {
        return data.super_graph_noeud
      },
      skip () {
        return (!this.id || !this.data || this.type !== 'supernoeud')
      },
      loadingKey: 'loading',
      watchLoading (isLoading) {
        this.supernoeudLoading = isLoading
      }
    },
    boutiqueItem: {
      query: require('@/graphql/queries/v2/boutiqueItemBy.gql'),
      variables () {
        return {
          attrs: { slug: this.id }
        }
      },
      update (data) {
        if (data?.boutiqueItemBy?.contenus === null) {
          this.$emit('echue')
        }
        return data.boutiqueItemBy
      },
      watchLoading (isLoading) {
        this.boutiqueLoading = isLoading
      },
      skip () {
        return (!this.id || !this.data || this.type !== 'boutique')
      }
    }
  },
  data () {
    return {
      loading: false,
      loadingInfoSup: false,
      supergraphLoading: false,
      supernoeudLoading: false,
      boutiqueLoading: false,
      boutiqueItem: null,
      infoSup: null,
      supergraph: null,
      supernoeud: null,
      infoId: null
    }
  },
  async mounted () {
    await this.$store.dispatch('App/fetchRessourcesExercices')
  },
  methods: {
    isHomeworkAble (exo) {
      let temp = true
      if (exo.graph && exo.graph.tag_invisibles.length > 0) {
        let tagTargetBlank = false
        let tagRedirect = false
        exo.graph.tag_invisibles.forEach((tag) => {
          if (tag.id === '0f9f6c95-06c0-47b3-89bf-11dcc837746f') {
            tagTargetBlank = true
          } else if (tag.id === '38b8bb6b-b987-4a7f-bee1-e93b0a7dd6f3') {
            tagRedirect = true
          }
        })
        if (tagTargetBlank && tagRedirect) {
          temp = false
        }
      }
      return temp
    },
    goToSerie () {
      let temp = 'https://www.laclassealamaison.com/lecon/'
      temp += this.supergraph.slug + '?dokUANE=' + this.$store.state.User.profile.info.username + '&access=' + this.$store.state.User.profile.info.accessCode + '&devoir=' + this.$route.params.id
      this.writeTokenInCookiesAndOpenLink(temp)
    },
    goToExo (graph, boutique) {
      let temp = boutique ? 'https://exercices.dokoma.com/exercice/' : 'https://www.laclassealamaison.com/lecon/'
      let find = false
      if (boutique) {
        temp += this.boutiqueItem.slug
        if (graph.__typename === 'GraphArbre') {
          temp += '/graph/' + graph.slug
        }
      } else if (graph) {
        temp += this.supergraph.slug + '/graph/' + graph.graph.id
      } else {
        const trouv = this.$store.state.App.listeGraph.filter((e) => {
          let find = e.supers_noeuds.filter(n => n.id === this.supernoeud.id)
          if (find.length > 0) {
            return e
          } else {
            find = e.tuiles.filter(t => t.id === this.supernoeud.id || t.supers_noeuds.find(s => s.id === this.supernoeud.id))
            if (find.length > 0) {
              return e
            }
          }
        })
        if (trouv.length > 0) {
          find = true
          temp += trouv[0].slug + '/graph/' + this.supernoeud.graph.id
        }
      }
      if (!boutique) {
        temp += '?dokUANE=' + this.$store.state.User.profile.info.username + '&access=' + this.$store.state.User.profile.info.accessCode + '&devoir=' + this.data.batch
      } else {
        temp += '?devoir=' + this.data.batch
      }
      if (find || graph) {
        this.writeTokenInCookiesAndOpenLink(temp)
      } else {
        console.error('pas trouve ', this.supernoeud)
      }
    }
  }
}
</script>

<template lang='pug'>
v-expansion-panel(:key='index', dark)
  template(v-if='type === "supernoeud"')
    v-expansion-panel-header(:color='$store.state.Preferences.darkMode ? "light darken-1" : "white"')
      template(v-if='supernoeudLoading')
        v-skeleton-loader(
          type='list-item-avatar-two-line'
        )
      v-list-item.w-100(v-else-if='supernoeud')
        v-list-item-avatar.pa1(color='light', size='30', v-if='$store.state.App.windowSize.width > 600')
          img(src='@/assets/images/logos/logo-classe-maison.svg', width='20')
        v-list-item-content(style='flex-shrink: 1;')
          div
            v-chip.mb2(v-if='getStatus && getStatus.title !== $t("messages.non-defini")', x-small, :color='getStatus ? getStatus.color : "grey"')
              span.ttu {{getStatus.title}}
            div(v-if='data.mark') {{data.mark}}
          v-list-item-subtitle.flex(v-if='supernoeud.graph && (supernoeud.graph.matieres || supernoeud.graph.niveaux)')
            template(v-for='(matiere, index) in supernoeud.graph.matieres')
              .item {{matiere.titre}}
            template(v-for='(niveau, index) in supernoeud.graph.niveaux')
              .item {{niveau.titre}}

          v-list-item-title(v-if='supernoeud.titre') {{supernoeud.titre}}
          v-list-item-subtitle {{$t('exercices.exercice')}}
        v-list-item-action
          v-btn(:small='!$store.state.App.mobileTemplate', :x-small='$store.state.App.mobileTemplate', rounded, color='secondary', depressed, outlined, @click.stop='goToExo()') {{$t('action.consulter')}}
    v-expansion-panel-content(v-if='supernoeud')
      div(v-if='supernoeud.description', v-html='supernoeud.description')
  template(v-else-if='type === "graph"')
    v-expansion-panel-header(:color='$store.state.Preferences.darkMode ? "secondary" : "white"')
      template(v-if='supergraphLoading')
        v-skeleton-loader(
          type='list-item-avatar-two-line'
        )

      v-list-item(v-else-if='supergraph', :dark='$store.state.Preferences.darkMode')
        v-list-item-avatar.pa1(color='light', size='30', v-if='$store.state.App.windowSize.width > 600')
          img(src='@/assets/images/logos/logo-classe-maison.svg', width='20')
        v-list-item-content
          div
            v-chip.mb2(v-if='getStatus && getStatus.title !== "Non défini"', x-small, :color='getStatus ? getStatus.color : "grey"')
              span {{getStatus.title}}
          v-list-item-title(v-if='supergraph.titre') {{supergraph.titre}}
          v-list-item-subtitle
            span.item {{$t('exercices.serie-exercices')}}
            span.item {{nombreExercices}} {{$t('exercices.exercices')}}
        v-list-item-action
          v-btn(:small='!$store.state.App.mobileTemplate', :x-small='$store.state.App.mobileTemplate', rounded, :color='$store.state.Preferences.darkMode ? "white" : "secondary"', depressed, :dark='$store.state.Preferences.darkMode', outlined, @click.stop='goToSerie()') {{$t('action.voir-serie')}}
    v-expansion-panel-content(v-if='supergraph')
      div.mb3(v-if='supergraph.description', v-html='supergraph.description')
      v-list(dense, style='padding: 0', rounded, v-if='supergraph.supers_noeuds && supergraph.supers_noeuds.length')
        template(v-for='(graph, ind) in supergraph.supers_noeuds', v-if='isHomeworkAble(graph)')
          v-list-item(v-if='graph.graph')
            v-list-item-content
              v-list-item-subtitle.flex(v-if='(graph.graph.matieres || graph.graph.niveaux)')
                template(v-for='(matiere, index) in graph.graph.matieres')
                  .item {{matiere.titre}}
                template(v-for='(niveau, index) in graph.graph.niveaux')
                  .item {{niveau.titre}}
              v-list-item-title
                span {{graph.titre}}
            v-list-item-action
              v-btn(x-small, rounded, color='secondary', depressed, dark, @click='goToExo(graph)') {{$t('action.consulter')}}
          v-divider(v-if='ind < supergraph.supers_noeuds.length - 1')
  template(v-else-if='type === "boutique" && boutiqueItem && boutiqueItem.contenus && boutiqueItem.contenus.length === activeContenu.length')
    v-expansion-panel-header(:color='$store.state.Preferences.darkMode ? "secondary" : "white"')
      v-skeleton-loader(v-if='boutiqueLoading', type='list-item-avatar-two-line')
      v-list-item(v-else-if='boutiqueItem', :dark='$store.state.Preferences.darkMode')
        v-list-item-content
          v-list-item-title {{boutiqueItem.titre}}
          div
            v-chip.mb2(v-if='getStatus && getStatus.title !== "Non-défini"', x-small, :color='getStatus ? getStatus.color : "grey"')
              span {{getStatus.title}}
    v-expansion-panel-content(v-if='boutiqueItem && activeContenu')
      v-list(dense, style='padding: 0', rounded, v-if='activeContenu.length > 0')
        template(v-for='(content, contdex) in activeContenu')
          v-list-item(:key='contdex')
            v-list-item-content
              v-list-item-title
                div(v-if='content.noeud', v-html='content.noeud.contenu.titre')
                span(v-else) {{content.titre}}
            v-list-item-action
              v-btn(x-small, rounded, color='secondary', depressed, dark, @click='goToExo(content, "boutique")') {{$t('action.consulter')}}
          v-divider(v-if='contdex < activeContenu.length - 1')
  template(v-else-if='type === "boutique" && boutiqueItem && boutiqueItem.contenus && boutiqueItem.contenus.length !== activeContenu.length')
    v-expansion-panel(v-if='activeContenu && activeContenu.length > 0', :key='boutiqueItem.contenu', dark, v-for='(content, contdex) in activeContenu')
      v-expansion-panel-header( hide-actions=false, @click='boutiqueItemSup(content.id)')
        v-list(dense, style='padding: 0', rounded)
          template(v-for='(content, contdex) in activeContenu')
            v-list-item(:key='"active-" + contdex')
              v-list-item-content
                div(v-if='content.noeud', v-html='content.noeud.contenu.titre')
                span(v-else) {{content.titre}}
              v-list-item-action
                v-btn(x-small, rounded, color='secondary', depressed, dark, @click='goToExo(content, "boutique")') {{$t('action.consulter')}}
      v-expansion-panel-content(v-if='infoSup')
        exercice-information(:infoSup='infoSup')
  template(v-else-if='type === "myquestions"')
    v-list(v-for='(noeud, index) in myquestionsExoList' :key='noeud.id' dense style='padding: 0' rounded)
      v-list-item
        v-list-item-content
          span.b {{ noeud.titre }}
          span {{ noeud.contenu.comportement}}
      v-divider(v-if="index < myquestionsExoList.length - 1")
  template(v-else-if="type === 'boutique' && boutiqueItem && boutiqueItem.contenus === null")
    p.f4.py-2.px-4.mb-0 {{ $t('exercices.contenu-not-found') }}

</template>
<style lang='sass' scoped>
  .item
    &:not(:first-of-type)
      &::before
        content: '•'
        padding-left: 8px
        padding-right: 8px
        font-weight: bold
</style>
