export default {
  methods: {
    getDevoirStatsByMail () {
      this.sendMailRequestWithVariables({ elements: [this.$route.params.id] })
    },
    getEleveStatsByMail () {
      this.sendMailRequestWithVariables({ usagers: [this.$route.params.id] })
    },
    getSalleStatsByMail () {
      this.sendMailRequestWithVariables({ salles: [this.$route.params.id] })
    },
    async sendMailRequestWithVariables (variables) {
      const { data } = await this.$apollo.mutate({
        mutation: require('@/graphql/mutations/v2/envoyerNoteDevoir.gql'),
        variables
      })
      const res = data.envoyerNoteDevoir
      this.mailConfirmation(res.id && res.errors === null)
    },
    mailConfirmation (succed) {
      if (succed) {
        this.$store.commit('App/setSnackbarGlobalContent', { type: 'success', titre: this.$t('alerte.succes'), description: this.$t('exercices.receive-results-mail-success') })
      } else {
        this.$store.commit('App/setSnackbarGlobalContent', { type: 'error', titre: this.$t('alerte.erreur'), description: this.$t('exercices.receive-results-mail-error') })
      }
      this.$store.commit('App/setShowSnackbarGlobal', true)
    }
  }
}
