// This is the new request, plugged in v2 endpoint, but using v1 data
// It was made with love by the Backend so that we can drop the previous mixins

export default {
  methods: {
    async getStats (filtre = {}, options = {}) {
      // Optional parameters
      const { includeHisto = false, periode = 'SEMAINE', extraData = {} } = options

      try {
        // Fetch stats
        const query = includeHisto
          ? require('@/graphql/queries/v2/analyse/statistiqueV1CalendrierCalendrierElementsWithHisto.gql')
          : require('@/graphql/queries/v2/analyse/statistiqueV1CalendrierCalendrierElementsWithoutHisto.gql')

        const { data } = await this.$apollo.query({
          query,
          variables: { filtre, periode },
          fetchPolicy: 'network-only'
        })
        const jsonData = data?.statistiqueV1CalendrierCalendrierElements?.data
        const stats = jsonData ? JSON.parse(jsonData) : {}

        // populate stats infos
        stats.termineCount = stats.count ? stats.count : 0
        stats.avgNote = Number.isFinite(stats.avg) ? Math.round(stats.avg) + '%' : this.$t('partage.n-d')
        stats.minNote = Number.isFinite(stats.min) ? Math.round(stats.min) + '%' : this.$t('partage.n-d')
        stats.maxNote = Number.isFinite(stats.max) ? Math.round(stats.max) + '%' : this.$t('partage.n-d')

        if (includeHisto) {
          stats.historique = data?.statistiqueV1CalendrierCalendrierElements?.historique?.map(h => {
            return {
              ...JSON.parse(h.data),
              debut: h.debut,
              fin: h.fin
            }
          })
        }

        const output = { ...stats, ...extraData }
        return output
      } catch (e) {
        console.error(e)
        return {}
      }
    }
  }
}
