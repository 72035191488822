<script>
import TableauElevesResults from '@/components/enfants/tableauElevesResults.vue'
import ExercicePreview from '@/components/devoirs/exercicePreview.vue'
import ExerciceStatsGraph from '@/components/exercices/exerciceStatsGraph.vue'
import ExerciceDetailsGraph from '@/components/exercices/exerciceDetailsGraph.vue'
import DeleteEvent from '@/components/dialogs/calendrier/deleteEvent'
import StatsPreview from '@/components/statistiques/statsPreview.vue'

import ChatControls from '@/mixins/chatControls.js'
import ConnectEleves from '@/mixins/connectEleve.js'
import LoadListeLecture from '@/mixins/loadListeLecture'
import CceStats from '@/mixins/cceStats'
import requestMail from '@/mixins/requestMail'
import displayNote from '@/mixins/displayNote'
import { uniqBy, flatten } from 'lodash-es'

export default {
  name: 'Devoir',
  mixins: [ChatControls, ConnectEleves, LoadListeLecture, CceStats, requestMail, displayNote],
  components: {
    TableauElevesResults,
    DeleteEvent,
    ExercicePreview,
    StatsPreview,
    ExerciceStatsGraph,
    ExerciceDetailsGraph
  },
  data () {
    return {
      searchDevoir: null,
      exercices: true,
      titre: 'Mon devoir génial',
      description: 'Mauris sapien felis, rutrum eget erat at, malesuada posuere lacus. Duis iaculis posuere lacus sit amet suscipit. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. In dapibus neque mauris, vitae faucibus quam sagittis vitae.',
      menuDateDebut: false,
      menuDateFin: false,
      dateDebut: null,
      dateFin: null,
      tempsMoyen: null,
      ressources: null,
      selectedNiveaux: null,
      selectedMatieres: null,
      devoirEditionActive: false,
      devoir: null,
      loadingDevoir: true,
      panel: 0,
      devoirPanel: 0,
      total: 0,
      termineCount: 0,
      avgNote: this.$t('partage.n-d'),
      minNote: this.$t('partage.n-d'),
      maxNote: this.$t('partage.n-d'),
      isEchue: false,
      stats: null
    }
  },
  computed: {
    uniqResu () {
      if (this.devoir && this.devoir.resultat) {
        return uniqBy(this.devoir.resultat, 'meta')
      } else {
        return []
      }
    },
    uniqDevoirsContenus () {
      return flatten(this.uniqResu.map(r => JSON.parse(r.meta)?.data?.contenu).filter(r => r !== undefined))
    },
    showElevesSelection: {
      set (val) { this.$store.commit('Dialogs/setElevesSelection', val) },
      get () { return this.$store.state.Dialogs.showElevesSelection }
    },
    devoirEditable () {
      return (this.$route.name && this.$route.query && this.$route.query.editable)
    },
    userInfos () {
      return this.$store.state.User.profile
    },
    elevesList () {
      const l = []
      // Very inefficent way to build the eleves list without duplication
      const devoirs = this.devoir?.resultat ?? []
      devoirs.forEach(d => {
        const usagers = d?.elementsUsagers?.resultat ?? []
        usagers.forEach(usager => {
          const i = l.findIndex(eleves => eleves.id === usager.id)
          if (i === -1) {
            l.push({ ...usager.usager, note: usager.note })
          }
        })
      })
      return l
    },
    isArchived () {
      const f = this.devoir?.resultat?.[0]?.finAt
      if (f && this.$dayjs().isAfter(f)) {
        return true
      }
      return this.devoir?.resultat?.every(r => r.statut === 'Archive')
    },
    createdAt () {
      return this.devoir?.resultat?.[0]?.createdAt ? this.$dayjs(this.devoir.resultat[0].createdAt).format() : null
    },
    noeuds () {
      const noeuds = []
      this.uniqResu.forEach(cce => {
        // eslint-disable-next-line no-unused-expressions
        cce?.contenu?.noeuds?.forEach(n => noeuds.push(n))
      })
      return noeuds
    }
  },
  watch: {
    '$store.state.App.snackbarGlobalContent': {
      deep: true,
      handler (newVal) {
        if (newVal && newVal.description && newVal.description.includes(this.$t('messages.devoir-supprime-success'))) {
          this.$router.push({ name: 'devoirs___' })
        }
      }
    }
  },
  apollo: {
    devoir: {
      query: require('@/graphql/queries/v2/calendrierCalendrierElements.gql'),
      variables () {
        return {
          filtre: {
            identifiants: [this.$route.params.id]
          }
          // identifiant: true
        }
      },
      update (data) {
        if (data.calendrierCalendrierElements && data.calendrierCalendrierElements.total > 0) {
          this.titre = data.calendrierCalendrierElements.resultat[0].titre
          this.description = data.calendrierCalendrierElements.resultat[0].description
          if (data.calendrierCalendrierElements.resultat[0].debutAt) {
            this.dateDebut = new Date(data.calendrierCalendrierElements.resultat[0].debutAt).toLocaleString(this.$i18n.locale + '-CA', { day: 'numeric', month: 'short', year: 'numeric' })
          }
          if (data.calendrierCalendrierElements.resultat[0].finAt) {
            this.dateFin = new Date(data.calendrierCalendrierElements.resultat[0].finAt).toLocaleString(this.$i18n.locale + '-CA', { day: 'numeric', month: 'short', year: 'numeric' })
          }
          this.total = [...new Set(data.calendrierCalendrierElements.resultat.map(item => item.meta))].length
        }
        return data.calendrierCalendrierElements
      },
      loadingKey: 'loading',
      fetchPolicy: 'network-only',
      watchLoading (isLoading) {
        this.loadingDevoir = isLoading
      },
      skip () { return !this.$route.params.id }
    }
  },
  mounted () {
    this.loadAllInfoForLectureId(this.$route?.params?.id, false)
    this.getStats()
  },
  methods: {
    isHomeworkAble (exo) {
      let temp = true
      if (exo.graph && exo.graph.tag_invisibles.length > 0) {
        let tagTargetBlank = false
        let tagRedirect = false
        exo.graph.tag_invisibles.forEach((tag) => {
          if (tag.id === '0f9f6c95-06c0-47b3-89bf-11dcc837746f') {
            tagTargetBlank = true
          } else if (tag.id === '38b8bb6b-b987-4a7f-bee1-e93b0a7dd6f3') {
            tagRedirect = true
          }
        })
        if (tagTargetBlank && tagRedirect) {
          temp = false
        }
      }
      return temp
    },
    customUserFilter (value, search, item) {
      return value != null && search != null && typeof value === 'string' && (value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1 || item.username.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1)
    },
    beforeRouteLeave (to, from, next) {
      if (this.devoirEditionActive) {
        const answer = window.confirm(this.$t('messages.confirm-sorti'))
        if (answer) {
          next()
        } else {
          next(false)
        }
      }
    },
    saveEdits () {
      this.devoirEditionActive = false
    },
    deleteDevoir () {
      this.$store.commit('Dialogs/setDeleteEvent', true)
      const elemEv = {
        identifiant: this.$route.params.id,
        name: this.devoir.resultat[0].titre,
        data: this.devoir.resultat,
        type: 'devoir'
      }
      this.$store.commit('Dialogs/setElemToDelete', elemEv)
    },
    removeEvent () {
      console.log('coucou')
      this.$router.push({ name: 'devoirs___' })
    },
    getUrlDokoma () {
      let temp = 'https://www.laclassealamaison.com'
      if (this.$store.state.User.profile) {
        temp += '?dokUANE=' + this.$store.state.User.profile.info.username + '&access=' + this.$store.state.User.profile.info.accessCode
      }
      return temp
    },
    async closeDevoir () {
      const isDeleted = await this.cloturerDevoir(this.$route.params.id)
      if (isDeleted) {
        this.$apollo.queries.devoir.refetch()
      }
    },
    async getStats () {
      const stats = await this.getCceStatsData(this.$route?.params?.id, { includeHisto: true })
      this.stats = stats
      this.termineCount = stats.termineCount
      this.avgNote = stats.avgNote
      this.minNote = stats.minNote
      this.maxNote = stats.maxNote
    }
  }
}
</script>

<template lang='pug'>
  .devoir-index.split-page
    template(v-if='!loadingDevoir && total > 0')
      delete-event(@removeEvent='removeEvent')
      .page-header(color='secondary')
        .header__inner-content
          v-btn.white--text.mb3(:small='$store.state.App.mobileTemplate', :to='{ name: "devoirs___" }', rounded, color='primary')
            font-awesome-icon.mr2.f4(:icon="['fas', 'arrow-left']")
            span {{ $t('exercices.tous-devoirs') }}
          div.flex-grow-1
          template
            .header-title()
              div.secondary-title {{ $t('exercices.devoir-et-exercice') }}
              //- pre {{data.calendrierCalendrierElements.resultat}}
              div.main-title {{titre}}
            .header-description {{description}}
            .header__extra-content
              v-chip.error--text.mr2.mt1(v-if="isEchue" color="error" outlined style='background-color:white !important')
                font-awesome-icon.f6.mr2(:icon="['fad', 'calendar-alt']")
                span {{ $t('action.ressource-echue') }}
              v-chip.primary.lighten-5.secondary--text.mr2.mt1(v-if="isArchived")
                font-awesome-icon.f6.mr2(:icon="['fad', 'calendar-alt']")
                span {{ $t('action.ressource-archive') }}
              v-chip.primary.lighten-5.secondary--text.mr2.mt1(v-if='dateFin', small)
                font-awesome-icon.f6.mr2(:icon="['fad', 'calendar-alt']")
                span {{ $t('messages.echeance-without-var') }}: {{dateFin}}
              v-chip.primary.lighten-5.secondary--text.mr2.mt1(small)
                font-awesome-icon.f6.mr2(:icon="['fad', 'file-edit']")
                span {{total}} {{ $t('partage.elements') }}{{total > 1 ? "s" : ""}}

      .page-actions
        v-card(color='primary' :to="{ name: 'devoirEdition', query: { id: $route.params.id, modif: true } }" v-ripple)
          v-card-title {{ $t('exercices.modif-devoir') }}
        v-card(color='primary', @click='deleteDevoir', v-ripple)
          v-card-title {{ $t('exercices.suppr-devoir') }}
        v-card(color='primary' @click='closeDevoir' v-ripple v-if="!isArchived")
          v-card-title {{ $t('exercices.mark-as-finish') }}
      .page-actions.mt-0
        v-card(color='primary' @click="getDevoirStatsByMail" v-ripple)
          v-card-title {{ $t('exercices.receive-results-mail') }}

      //- GRAPH / STATS
      div.page__main-content(v-if="termineCount !== 0")
        section.fullsize-section
          v-sheet.section-card(style='padding: 0' light :color='$store.state.Preferences.darkMode ? "light" : "white"')
            exercice-stats-graph(
              :contenus="uniqResu"
              :lectureGroups="lectureGroups"
              :start="createdAt"
            )

      div.page__main-content

        //- CONTENU
        section.large-section
          .section-subtitle__header
            h2.section-subtitle {{ $t('exercices.devoir-content') }}
          v-sheet.section-card(v-if='devoir', style='padding: 0; overflow:hidden;', light, :color='$store.state.Preferences.darkMode ? "light" : "white"')
            v-expansion-panels.exercices-panel(accordion, v-model='devoirPanel', :color='$store.state.Preferences.darkMode ? "accent" : "light"')
              template(v-for="(item,i) in uniqResu", v-if='isHomeworkAble(item)')
                exercice-preview(:data='item' :index='i' @echue="isEchue = true")
          v-sheet.section-card(v-else, light, :color='$store.state.Preferences.darkMode ? "light" : "white"')
            div.empty-content__wrapper
              div {{ $t('exercices.devoir-vide') }}
              v-btn.mt3(rounded, color='primary') {{ $t('exercices.ajout-exo') }}
              v-btn.mt3(rounded, color='primary', outlined, :href='getUrlDokoma', target='_blank')
                img.mr2(src='@/assets/images/logos/logo-classe-maison.svg', height='20px')
                span {{ $t('action.explore-classe') }}

          //- DETAILS CONTENU DONUT GRAPH
          v-sheet.section-card.mt-5(style='padding: 0' light :color='$store.state.Preferences.darkMode ? "light" : "white"')
            exercice-details-graph(:devoirsContenus="uniqDevoirsContenus" :noeuds="noeuds")

        //- STATS
        section.small-section
          .section-subtitle__header
            h2.section-subtitle {{ $t('partage.statistiques') }}
            v-btn(small, outlined, rounded, @click='', disabled) {{ $t('action.plus-statistiques') }}
          div
            stats-preview(:lineGraph='false')
              template(slot='numberStatsFirstRow')
                div.stat(v-if="avgNote")
                  span.stat-number {{ termineCount }}
                  div.stat-label {{ $tc('exercices.exercice-termines', termineCount) }}
                div.stat
                  span.stat-number {{ ratedNote(avgNote) }}
                  div.stat-label {{ $t('partage.moyenne-general') }}

            stats-preview.mt3(v-if="(minNote !== $t('partage.n-d') || maxNote !== $t('partage.n-d')) && termineCount > 1" :lineGraph='false')
              template(slot='numberStatsFirstRow')
                div.stat
                  span.stat-number(v-if='minNote') {{ ratedNote(minNote) }}
                  div.stat-label {{ $t('exercices.worst-note') }}
                div.stat
                  span.stat-number(v-if='maxNote') {{ ratedNote(maxNote) }}
                  div.stat-label {{ $t('exercices.meilleur-note') }}

          //- ELEVES
          .section-subtitle__header.mt3
            h2.section-subtitle {{ $t('eleves.eleves-maj') }}
          v-sheet.section-card.groupes(v-if='lectureGroups.length > 0', style='padding: 0; overflow: hidden;', light, :color='$store.state.Preferences.darkMode ? "light" : "white"')
            v-expansion-panels(accordion, v-model='panel')
              v-expansion-panel(v-for='(group, index) in lectureGroups' :key='group.proprietaireId')
                v-expansion-panel-header(color='secondary', dark)
                  v-list-item.mr3
                    v-list-item-content.white--text.b {{ group.titre ? group.titre : $t('eleves.groupe-sans-titre')}}
                    v-list-item-action
                      v-btn(x-small, rounded, color='primary', depressed, :to='{ name: "groupeDetail___" + $i18n.locale, params: { lang: $i18n.locale, id: group.calendrier.proprietaire.id } }', @click.prevent) {{ $t('action.groupe-gestion') }}
                v-expansion-panel-content(style='padding:0;', :color='$store.state.Preferences.darkMode ? "light" : "light"', light)
                  tableau-eleves-results(:key='index' :calendrier='group.calendrier' :maxShow='10' identifiant :usagers="group.usagers" :showNote="false")

          v-sheet.section-card(v-else, light, :color='$store.state.Preferences.darkMode ? "light" : "white"')
            div.empty-content__wrapper
              div {{ $t('exercices.non-attribue') }}

    template(v-else-if='!loadingDevoir')
      .page-header(color='secondary')
        .header__inner-content
          v-btn.white--text.mb3(:small='$store.state.App.mobileTemplate', :to='{ name: "devoirs___" + $i18n.locale, params: { lang: $i18n.locale } }', rounded, color='primary')
            font-awesome-icon.mr2.f4(:icon="['fas', 'arrow-left']")
            span {{ $t('exercices.tous-devoirs') }}
          div.flex-grow-1
          template(v-if='!devoirEditionActive')
            .header-title()
              div.secondary-title {{ $t('exercices.devoir-et-exercice') }}
              div.main-title {{ $t('exercices.devoir-inconnu') }}
            .header-description {{ $t('exercices.devoir-inconnu-supp') }}
</template>
<style lang='sass'>
  @import 'src/styles/pages/_devoirDetail'
  .section-card.groupes
    .v-expansion-panels
      .v-expansion-panel
        &:not(:last-child)
          .v-expansion-panel-header
            border-bottom: solid 1px rgba(255,255,255,0.5) !important
        .v-expansion-panel-header
          border-bottom: none !important
          .v-expansion-panel-header__icon
            .v-icon
              color: white !important
        .v-expansion-panel-content
          .v-expansion-panel-content__wrap
            padding: 0
</style>
